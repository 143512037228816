import {
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
  tokens,
  VisuallyHidden,
} from '@vp/swan'
import { FeedbackKey } from './feedback-module'

type FeedbackRatingProps = {
  feedbackKey: FeedbackKey
  selectedValue?: string
  onSelectedValueChange: (feedbackKey: FeedbackKey, newValue: string) => void
}

/**
 * A selection set that collects ratings using an emoji for each rating value 1-5. How fun! 🤪
 */
export const FeedbackRating = ({
  feedbackKey,
  selectedValue,
  onSelectedValueChange,
}: FeedbackRatingProps) => {
  const ratingToEmojiMap = {
    '1': '😰',
    '2': '🙁',
    '3': '😐',
    '4': '🙂',
    '5': '😍',
  }

  return (
    <>
      <SelectionSet
        variant="single-select"
        skin="buttons"
        selectedValue={selectedValue || null}
        onSelectedValueChange={newValue => {
          onSelectedValueChange(feedbackKey, newValue)
        }}
      >
        {Object.entries(ratingToEmojiMap).map(([rating, emoji]) => (
          <SelectionSetInput key={rating} value={rating}>
            <SelectionSetLabel
              mb={0}
              style={{ fontSize: tokens.SwanSemSizeIconStandard }}
            >
              <VisuallyHidden>{rating}</VisuallyHidden>
              <span aria-hidden="true">{emoji}</span>
            </SelectionSetLabel>
          </SelectionSetInput>
        ))}
      </SelectionSet>
    </>
  )
}
