import { Box, MainContentStart, SiteMain } from '@vp/swan'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'
import { CommonPageContext } from '../../gatsby/page-types'
import { BtnBackToTop } from './back-to-top/back-to-top.component'
import { Footer } from './footer/footer.component'
import {
  containerTop,
  mainContent,
  siteWrapper,
  space,
  withSideNav,
} from './layout.module.scss'

export type PageType = { navType?: 'vds' | 'swan' }

type LayoutProps = PropsWithChildren<{
  pageContext?: CommonPageContext
  pageSlugPrefix?: string
  prevPage?: any
  nextPage?: any
  hasSpacing?: boolean
  showSideNav?: boolean
}>

export const Layout = ({
  children,
  pageContext,
  pageSlugPrefix,
  prevPage,
  nextPage,
  hasSpacing = true,
  showSideNav = true,
}: LayoutProps) => {
  const { prevPageSlug, nextPageSlug } = pageContext || {}
  return (
    <Box className={classNames(siteWrapper, { [withSideNav]: showSideNav })}>
      <SiteMain className={classNames(mainContent, { [space]: hasSpacing })}>
        <MainContentStart className={containerTop} />
        {children}
        <BtnBackToTop />
      </SiteMain>
      <Footer />
    </Box>
  )
}
