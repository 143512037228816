import { Button, Icon, SwanStyleProps } from '@vp/swan'
import { useRef } from 'react'
import { useFabPosition } from '../../../hooks/use-fab-position'
import { backToTopBtn } from './back-to-top.module.scss'

export const BtnBackToTop = (props: SwanStyleProps) => {
  const btnRef = useRef<HTMLAnchorElement>()

  // Positions the button with respect to other existing fabs on the page
  useFabPosition(btnRef)

  return (
    <Button
      render={props => (
        <a
          href="#maincontentstart"
          aria-label="Back to top"
          ref={btnRef}
          className={props.className}
          id="back-to-top-fab"
        >
          {props.children}
        </a>
      )}
      buttonShape="round"
      title="Back To Top"
      {...props}
      className={backToTopBtn}
    >
      <Icon iconType="caretUp" />
    </Button>
  )
}
