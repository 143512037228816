export const toCapitalize = (str: string) =>
  str.slice(0, 1).toUpperCase() + str.slice(1)

/**
 * Converts a kebab-case string to Title Case.
 *
 * @param {string} input - The kebab-case string to convert.
 * @returns {string} The Title Case version of the input string.
 *
 * @example
 * kebabToTitleCase("this-is-an-example"); // "This Is An Example"
 */
export const kebabToTitleCase = (input: string): string => {
  return input
    .split('-') // Split the string into words by the '-' delimiter
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' ') // Join the words with a space
}
