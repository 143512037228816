import { Box } from '@vp/swan'
import React, { useMemo } from 'react'
import { SlugPrefix } from '../../constants/slug-prefix'
import { kebabToTitleCase } from '../../utils/text-transformer.util'
import { FeedbackFab } from './feedback-fab'
import { FeedbackFormPreview } from './feedback-form-preview'
import { FeedbackModal } from './feedback-modal'

export type FeedbackKey =
  | 'component'
  | 'docs'
  | 'swan'
  | 'comment'
  | 'submittedBy'

export type Feedback = {
  [K in FeedbackKey]: {
    input?: string
    prompt: string
    isEnabled: boolean
    isRequired: boolean
    isValid: boolean
    type: 'rating' | 'text-input' | 'text-area'
  }
}

type FeedbackModuleProps = {
  pageCategory: string
  pageSlug: string
  pageTab?: string
}

const apiUrl = process.env.GATSBY_FEEDBACK_API_URL || null

export type ApiResponseStatus =
  | 'IDLE' // POST request does not exist
  | 'INVALID' // POST request failed validation
  | 'PENDING' // POST request is in flight
  | 'SUCCESS' // POST request was successful
  | 'ERROR' // POST request failed

/**
 * A user feedback collection mechanism that collects that feedback in two ways:
 *
 * 1️⃣ Floating action button ("FAB")
 * A button fixed to the bottom right of the window that launches the modal with the survey.
 *
 * 2️⃣ Form preview
 * An Alert Box situated at the bottom of the page that displays the first question of the survey.
 * On selection of a response, it launches the modal with the survey, prefilled with their selection.
 *
 * The collected feedback is validated, formatted, and sent to an AWS proxy configured with a Zapier webhook.
 */
export const FeedbackModule = ({
  pageSlug,
  pageTab,
  pageCategory,
}: FeedbackModuleProps) => {
  const pageName = kebabToTitleCase(pageSlug)

  const isComponentOrFoundationPage =
    pageCategory === SlugPrefix.componentPage ||
    pageCategory === SlugPrefix.foundationPage

  const defaultFeedbackConfig: Feedback = {
    component: {
      prompt: `How does ${pageName} meet your needs?`,
      isEnabled: isComponentOrFoundationPage, // Only show this question for certain pages
      isRequired: isComponentOrFoundationPage, // Only require this question for certain pages
      isValid: true,
      type: 'rating',
    },
    docs: {
      prompt: `How would you rate the SWAN documentation?`,
      isEnabled: true,
      isRequired: true,
      isValid: true,
      type: 'rating',
    },
    swan: {
      prompt: `Overall, how satisfied are you with SWAN?`,
      isEnabled: true,
      isRequired: true,
      isValid: true,
      type: 'rating',
    },
    comment: {
      prompt: `Could you provide more details?`,
      isEnabled: true,
      isRequired: false, // Not required by default, but changed to required when a negative rating is collected
      isValid: true,
      type: 'text-area',
    },
    submittedBy: {
      prompt: `If you're interested, include your name for a follow up conversation`,
      isEnabled: true,
      isRequired: false,
      isValid: true,
      type: 'text-input',
    },
  }

  const [feedback, setFeedback] = React.useState<Feedback>(
    defaultFeedbackConfig,
  )

  const [apiResponseStatus, setApiResponseStatus] =
    React.useState<ApiResponseStatus>('IDLE')

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  // Updates the `comment` field to be required on input of negative feedback (a rating <= 3)
  const commentIsRequired = useMemo(() => {
    return Object.values(feedback)
      .filter(val => val.type === 'rating')
      .some(rating => Number(rating.input) <= 3)
  }, [feedback])

  // Revalidates the `comment` input on change of its `isRequired` property, since it depends on other input
  const commentIsValid = useMemo(() => {
    return (
      apiResponseStatus === 'IDLE' ||
      !feedback.comment.isRequired ||
      !!feedback.comment.input
    )
  }, [apiResponseStatus, feedback])

  // Updates the `comment` field if its properties have changed since last render
  if (feedback.comment.isRequired !== commentIsRequired) {
    setFeedback(prevFeedback => ({
      ...prevFeedback,
      comment: {
        ...prevFeedback.comment,
        isRequired: commentIsRequired,
        isValid: commentIsValid,
      },
    }))
  }

  // Validates the given input according to its `isRequired` validation rule
  const isValid = (feedbackKey: FeedbackKey, input: string) =>
    !feedback[feedbackKey].isRequired || !!input

  // Validates all input and returns `true` if all fields are valid, `false` otherwise
  const validateForm = () => {
    let isFormValid = true

    const newFeedback = { ...feedback }

    Object.keys(feedback).forEach((feedbackKey: FeedbackKey) => {
      const isInputValid = isValid(feedbackKey, feedback[feedbackKey].input)

      newFeedback[feedbackKey] = {
        ...newFeedback[feedbackKey],
        isValid: isInputValid,
      }

      if (!isInputValid) {
        isFormValid = false
      }
    })

    setFeedback(newFeedback)
    return isFormValid
  }

  // Updates feedback state with user input
  const handleChange = (feedbackKey: FeedbackKey, input: string) => {
    setApiResponseStatus('IDLE')
    const newFeedback = { ...feedback }

    newFeedback[feedbackKey] = {
      ...newFeedback[feedbackKey],
      input,
      isValid: isValid(feedbackKey, input),
    }

    setFeedback(newFeedback)
  }

  // Validates, formats, and sends the feedback data to the API
  const handleSubmit = async (): Promise<void> => {
    // Validate the feedback form input, and bail if invalid ✌🏻
    if (!validateForm()) {
      setApiResponseStatus('INVALID')
      return
    }

    // Extract feedback input into a key-value pair of feedback keys and user inputs
    const feedbackValues = Object.entries(feedback).reduce(
      (acc, [feedbackKey, data]) => {
        acc[feedbackKey] = data.input
        return acc
      },
      {},
    )

    // Format and send feedback & metadata (source, page title, tab, and timestamp),
    // and update the API response status accordingly
    try {
      setApiResponseStatus('PENDING')
      await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          {
            source: 'Doc Site',
            pageCategory,
            page: pageName,
            tab: pageTab,
            date: new Date().toISOString(),
            feedback: feedbackValues,
          },
          null,
          2,
        ),
      })
      setApiResponseStatus('SUCCESS')
    } catch (e) {
      console.error('Error submitting feedback to proxy:', e)
      setApiResponseStatus('ERROR')
    }
  }

  // Resets the feedback state and API response status on close of the modal
  const handleDismiss = (): void => {
    setIsModalOpen(false)
    setFeedback(defaultFeedbackConfig)
    setApiResponseStatus('IDLE')
  }

  if (!apiUrl) return null

  return (
    <Box mt="10">
      <FeedbackFab
        pageName={pageName}
        onClick={() => setIsModalOpen(true)}
        showPageName={isComponentOrFoundationPage}
      />

      <FeedbackFormPreview
        feedback={feedback}
        onChange={(feedbackKey: FeedbackKey, input: string) => {
          handleChange(feedbackKey, input)
          setIsModalOpen(true)
        }}
      />

      <FeedbackModal
        feedback={feedback}
        apiResponseStatus={apiResponseStatus}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onDismiss={handleDismiss}
        isModalOpen={isModalOpen}
      />
    </Box>
  )
}
