import { tokensRaw } from '@vp/swan'
import { MutableRefObject, useEffect } from 'react'

const buttonIds = ['back-to-top-fab', 'feedback-fab']

/**
 * Given a button ref, updates its CSS to position it with respect to the other floating action buttons on the page.
 *
 * To add a fab to the page...
 * - Create a component that renders a `button` (or an `a` styled as a `button`)
 * - Use the `fab-btn` mixin to apply shared styles to the button that make it "float".
 * - Give the button an `id` and place that `id` in the `buttonIds` list in the order it should appear (right-to-left).
 * - Give the button a ref, which can be generated using React's `useRef` hook.
 * - Finally, call this hook from the top of your component, passing the ref as the parameter.
 *
 * @param btnRef ref of the button to be positioned
 */
export function useFabPosition(
  btnRef: MutableRefObject<HTMLButtonElement | HTMLAnchorElement>,
) {
  useEffect(() => {
    const order = buttonIds.findIndex(id => id === btnRef.current.id)

    const spaceBetweenFabs = parseInt(tokensRaw.SwanSemSpaceBetweenActions)

    const spaceFromEdge = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--vds-site-fab-margin',
      ),
    )

    const getButtonWidth = (order: number) => {
      const buttonId = buttonIds[order]
      const button = document.querySelector(`#${buttonId}`)
      return button?.getBoundingClientRect().width || 0
    }

    const listener = () => {
      if (btnRef.current) {
        let originalPosition: number
        let scrolledPosition: number

        // Position the first button to be off-page unless the user is scrolling,
        // then position to the far-right of the page
        if (order === 0) {
          originalPosition = -120
          scrolledPosition = spaceFromEdge
        }

        // Position the next farthest button to replace the first button when it is off-page,
        // otherwise position it to the left of the first button
        else if (order === 1) {
          originalPosition = spaceFromEdge
          scrolledPosition =
            spaceFromEdge + getButtonWidth(order - 1) + spaceBetweenFabs
        }

        // Position all other buttons to the left of the visible button(s)
        else {
          let sumOfPreviousButtonWidths = 0

          for (let i = 0; i < order; i++) {
            sumOfPreviousButtonWidths += getButtonWidth(i) + spaceBetweenFabs
          }

          originalPosition =
            spaceFromEdge + getButtonWidth(order - 1) + spaceBetweenFabs

          scrolledPosition = spaceFromEdge + sumOfPreviousButtonWidths
        }

        btnRef.current.style.right =
          window.scrollY > window.innerHeight / 2
            ? `${scrolledPosition}px`
            : `${originalPosition}px`
      }
    }

    window.addEventListener('scroll', listener, { passive: true })
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [btnRef])
}
