import { AlertBox, Box, FlexBox } from '@vp/swan'
import { Feedback, FeedbackKey } from './feedback-module'
import { FeedbackRating } from './feedback-rating'

type FeedbackFormPreviewProps = {
  feedback: Feedback
  onChange: (feedbackKey: FeedbackKey, newValue: string) => void
}

/**
 * An Alert Box that displays the first "rating" question of the survey and saves the selection in state.
 */
export const FeedbackFormPreview = ({
  feedback,
  onChange,
}: FeedbackFormPreviewProps) => {
  // The feedback prompts relevant to the current page are flagged with `isEnabled: true`.
  // Grab the first relevant "rating" prompt to serve as the preview.
  const feedbackKey = Object.keys(feedback).find(
    key => feedback[key].isEnabled && feedback[key].type === 'rating',
  ) as FeedbackKey

  return (
    <AlertBox>
      <FlexBox
        alignItems="start"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={'between-actions'}
      >
        <Box>{feedback[feedbackKey].prompt}</Box>
        <FeedbackRating
          feedbackKey={feedbackKey}
          onSelectedValueChange={onChange}
        />
      </FlexBox>
    </AlertBox>
  )
}
