import {
  FormError,
  FormField,
  FormInputGroup,
  FormLabel,
  FormLabelOptional,
  StandardForm,
  TextArea,
  TextInput,
  VisuallyHidden,
} from '@vp/swan'
import { Feedback, FeedbackKey } from './feedback-module'
import { FeedbackRating } from './feedback-rating'

type FeedbackFormProps = {
  feedback: Feedback
  onChange: (feedbackKey: FeedbackKey, value: string) => void
  onSubmit: () => void
}

/**
 * A form for collecting either "rating" or "text" user feedback, with support for enforcing required fields.
 */
export const FeedbackForm = ({
  feedback,
  onChange,
  onSubmit,
}: FeedbackFormProps) => {
  return (
    <StandardForm
      aria-labelledby="feedback-form-modal-title"
      onSubmit={onSubmit}
    >
      {Object.keys(feedback).map((feedbackKey: FeedbackKey) => {
        const { isEnabled, isRequired, input, prompt, type, isValid } =
          feedback[feedbackKey]

        if (!isEnabled) return null

        return (
          <>
            <FormField key={feedbackKey} aria-required={isRequired}>
              <FormLabel>
                {prompt}{' '}
                {isRequired ? (
                  <>
                    <span aria-hidden="true">*</span>
                    <VisuallyHidden>Required</VisuallyHidden>
                  </>
                ) : (
                  <FormLabelOptional>(Optional)</FormLabelOptional>
                )}
              </FormLabel>
              <FormInputGroup>
                {type === 'rating' && (
                  <FeedbackRating
                    feedbackKey={feedbackKey}
                    selectedValue={input}
                    onSelectedValueChange={onChange}
                  />
                )}
                {type === 'text-input' && (
                  <TextInput
                    value={input}
                    onChange={event =>
                      onChange(feedbackKey, event.target.value)
                    }
                  />
                )}
                {type === 'text-area' && (
                  <TextArea
                    value={input}
                    onChange={event =>
                      onChange(feedbackKey, event.target.value)
                    }
                    rows={2}
                    fullWidth
                  />
                )}
                {!isValid && (
                  <FormError>Oops, this field is required!</FormError>
                )}
              </FormInputGroup>
            </FormField>
          </>
        )
      })}
    </StandardForm>
  )
}
